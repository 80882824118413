import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import Stack from '@material-ui/core/Stack';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Slider from '@material-ui/core/Slider';
import SHlogo from './SHhorizwhite.svg'
import Divider from '@material-ui/core/Divider';


const valuepercent = 0.02;
const flatbase = 6000;
const familymultiple = 0.05;
const budgetlow = 0.60;


export default function ButtonAppBar() {


  const [valuation, setvaluation] = useState(250000);
  const getvaluation = (event) => { setvaluation(event.target.value); };

  const [families, setfamilies] = useState(1);
  const getfamilies = (event) => { setfamilies(event.target.value);  };

  const [budget, setbudget] = useState(12800);
  const [lowbudget, setlowbudget] = useState(12800);

  const [sow, setsow] = useState('Included in the budget is a complete review of the prosecution history, assignment records (including recorded liens), reasons for allowance and relative patent scope, clear invalidity issues such as patent eligibility under 35 USC 101.');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [rangevalue, setrangevalue] = useState(225000)

  const rangeSelector = (event, newValue) => {
    setrangevalue(newValue);
    setvaluation(newValue);
    
  };
  useEffect(() => {
    // BUDGET FORMULA
    setbudget(flatbase + (valuation * valuepercent) + (valuation * familymultiple * families));
    setlowbudget(budget * budgetlow)
    switch (true) {
      case (budget < 30000): setsow("Complete review of the prosecution history. Assignment records (including recorded liens), reasons for allowance and relative patent scope, clear invalidity issues such as patent eligibility under 35 USC 101. Detailed report with weaknesses and strengths of patent portfolio. Where appropriate, recalculation of the portfolio valuation."); break;
      case (budget < 40000): setsow("Complete review of the prosecution history, assignment records (including recorded liens), reasons for allowance and relative patent scope, clear invalidity issues such as patent eligibility under 35 USC 101.  Search for analogous art possibly missed by patent examiner. Detailed report with weaknesses and strengths of patent portfolio. Where appropriate, recalculation of the portfolio valuation."); break;
      case (budget < 60000): setsow("Complete review of the prosecution history, assignment records (including recorded liens), reasons for allowance and relative patent scope, clear invalidity issues such as patent eligibility under 35 USC 101.  Search for analogous art possibly missed by patent examiner.  Expanded prior art search to foreign jurisdictions. Detailed report with weaknesses and strengths of patent portfolio. Where appropriate, recalculation of the portfolio valuation."); break;
      case (budget < 80000): setsow("Complete review of the prosecution history, assignment records (including recorded liens), reasons for allowance and relative patent scope, clear invalidity issues such as patent eligibility under 35 USC 101.  Search for analogous art possibly missed by patent examiner.  Expanded prior art search to foreign jurisdictions.  Detailed check of concurrent prosecutions for failure to submit material prior art (inequitable conduct). Detailed report with weaknesses and strengths of patent portfolio. Where appropriate, recalculation of the portfolio valuation."); break;
      case (budget >= 80000): setsow("Complete review of the prosecution history, assignment records (including recorded liens), reasons for allowance and relative patent scope, clear invalidity issues such as patent eligibility under 35 USC 101.  Litigation-grade search for analogous art in domestic and foreign jurisdictions.  Detailed check of concurrent prosecutions for failure to submit material prior art (inequitable conduct).  Articulated design-around embodiments that do not read on the prior art.  Claim construction liabilities and strengths. Detailed report with weaknesses and strengths of patent portfolio. Where appropriate, recalculation of the portfolio valuation."); break;
      //default: setsow("Seems fine at " + budget); break;
    }
    //console.log('Budget: ' + budget + ' Amount: ' + valuation + ' Families: ' + families);

  }, [budget, valuation, families, sow, rangevalue]);

  return (

    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Container>
          <img src={SHlogo} style={{ maxWidth: 400, padding: 10 }} />
          </Container>
          {/* <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
            Patent Investigation Services
          </Typography> */}

        </Toolbar>
      </AppBar>

      <Container>

        <Paper elevation={3} style={{ padding: 12, marginTop: 30 }}>
          <Box style={{ padding: 10 }}>
            <div style={{ padding: 10 }}>
              <b>Smith & Hopen</b> provides intellectual property investigations for investments of $100,000 or more. Our firm has generated patents subject to well over $1.5 billion in
              successful due dilligence review. Every patent should be written in anticipation of litigation. However, only an expert in patent law can validate the quality of a litigation-grade
              patent.  By the same token, an expert can find the flaws, limitations or even invalidity of a patent.
            </div>
            <Divider />
            <div style={{ padding: 10 }}>
              <b>Calculator: </b>This calculator provides guidance on what an investor should budget for an analysis. The investment in analysis depends on the proposed valuation of the 
              patent portfolio and also the number of patent families (e.g., groups of separate technologies).
            </div>
          </Box>
          <Box style={{ padding: 30 }}>
            <Grid container spacing={2} >
              <Grid item xs={6} >
                <Box style={{ marginTop: 5 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">Proposed Patent Portfolio Valuation</InputLabel>
                  <OutlinedInput
                    id="amount"
                    fullWidth
                    value={valuation}
                    //     onChange={handleChange('amount')}
                    onChange={getvaluation}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="Amount"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box style={{ marginTop: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">Patent Families <HelpOutlineIcon fontSize='small' onClick={handleOpen} /></InputLabel>
                  <OutlinedInput
                    id="patentquantity"
                    value={families}
                    // onChange={handleChange('patentquantity')}
                    onChange={getfamilies}
                    label="Patent Families"
                  />
                </Box>

              </Grid>

              <Box sx={{ padding: 2, width: 1 }}>
                <Box sx={{ width: 1 }}>
                  <Slider
                    size="small"
                    defaultValue={250000}
                    step={50000}
                    aria-label="Valuation"
                    valueLabelDisplay='off'
                    min={100000}
                    max={1000000}
                    onChange={rangeSelector}
                  />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Paper>
      </Container>
      <Container>
        <Paper elevation={3} style={{ padding: 12, marginTop: 30 }}>
          <Box style={{ marginTop: 5 }}>
            <Typography variant="h6" gutterBottom component="div">
              Preliminary Analysis: <NumberFormat value={2000 + lowbudget / 5} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Typography>
            <Box>
              A preliminary analysis looks for "low hanging fruit." Some defects in patents are immediately evident, fatal to valuation and do not require a full-blown analysis.
              Ownership issues, abandonment, expiration and extremely narrow patent scope are just some examples.
            </Box>
          </Box>
        </Paper>
      </Container>


      <Container>
        <Paper elevation={3} style={{ padding: 12, marginTop: 30 }}>
          <Box style={{ marginTop: 5 }}>
            <Typography variant="h6" gutterBottom component="div">
              Full Analysis Budget: <NumberFormat value={lowbudget} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /> to <NumberFormat value={budget} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Typography>
            <Box>
              {sow}
            </Box>
          </Box>
        </Paper>
      </Container>



      <Container>
        <Paper elevation={3} style={{ padding: 12, marginTop: 30 }}>
          <Box style={{ marginTop: 5 }}>
            <Typography variant="h6" gutterBottom component="div">
              Smith & Hopen PA
            </Typography>
            180 Pine Avenue North <br />
            Oldsmar Florida 34677
            <br />
            (813) 925-8505

          </Box>
        </Paper>
      </Container>
      <Box>
           
      </Box>
      <div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Patent Families
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                A patent family is a collection of patent applications covering the same or similar technical content.
                The applications in a family are related to each other through priority claims. Because the technology is
                similar, it takes us less time to digest these related applications.
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>
    </Box>


  );
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};